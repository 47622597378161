.bridge-slider {
   
  overflow-x: scroll;
  padding-top: 0%;
}
.bridge-container {
  margin-bottom: 0px; 
  width: 100%; 
   display: flex;
  flex-direction: row;
  overflow-x: hidden; 
}
.bridge-legend {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  width: 100%;
  overflow-y: hidden;
  position: relative;
  top: 70%;
}
.bridge-nb-stories {
  display: flex;
  flex-direction: row;
  font-weight: 900;
  color: rgb(236, 66, 103);
  margin-top: 0%;
  white-space: "normal";
}
.bridge-name {
  color: var(--color-white);
  font-family: courgette;
  font-size: 22px;
  padding-left: 5px;
  margin-bottom: 6%;
}

.bride-img {
  border-radius: 30px;
}

.slider-bridge {
  background-color: transparent;
  height: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
}

.slider-bridge::-webkit-scrollbar {
  display: none;
}

.m-bridge-legend {
  display: flex;
  flex-direction: row;
  flex-flow: wrap; 
  width: 100%;
}
.m-bridge-nb-stories {
  font-weight: 600;
  font-size: 20px;
  color: whitesmoke;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.m-bridge-name {
  color: whitesmoke;
  font-size: 20px;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  display: inline-flex;
  white-space: "normal";
}
.m-bridge-town {
  padding-left: 5px;
  font-size: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: white;
  display: flex;
  white-space: "normal";
}
@media screen and (max-width: 1200px) {
  .m-bridge-legend {
    width: auto; 
  }
  .m-bridge-nb-stories {
    font-weight: 600;
    font-size: 20px;
  }
  .m-bridge-name {
    font-size: 20px;
  }
  .m-bridge-town {
    font-size: 20px;
    white-space: normal;
  }
}
@media screen and (max-width: 600px) {
  .m-bridge-container {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .m-bridge-legend {
    width: auto; 
  }
  .m-bridge-nb-stories {
    font-weight: 600;
    font-size: 17px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .m-bridge-name {
    font-size: 17px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .m-bridge-town {
    font-size: 14px;
    margin-left: 0px;
    padding-left: 0px;
    white-space: normal;
  }
}
.btn-bridges {
  border-radius: 60%;
  background: transparent;
  color: rgb(236, 66, 103);
  font-size: 30px;
  font-weight: 600px;
  position: relative; 
  width: 80px;
  height: 80px;
  border-color: rgb(236, 66, 103);
  border-width: 2px;
  transform: translateY(-50%);
  top: 50%;
}
.btn-bridges:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(236, 66, 103);
  color: white;
}
.leftgrid {
  padding-top: 2px;
  margin-top: 5px;
}
.centergrid {
  padding-top: 2px;
  margin-top: 5px;
}
.rightgrid {
  margin-top: 5px;
  padding-top: 2px;
}

.bridgeheader {
  display: inline-flex;
  height: 60px;
  padding-left: 90px;
  padding-top: 10px;
  width: 100%;
}
.btn-bridges1 {
  border-radius: 15px;
  background: transparent;
  color: rgb(236, 66, 103);
  font-size: 20px;
  font-weight: 900px;
  width: 130px;
  margin-left: 700px;
  height: 40px;
  border-color: rgb(236, 66, 103);
  border-width: 2px;
}
.btn-bridges1:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(236, 66, 103);
  color: white;
}
.previous {
  background-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}
a.previous {
  margin-top: 22px;
  margin-left: 900px;
  padding: 8px 20px;
  height: 25%;
  font-size: 30px;
}
.previous:hover {
  background-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}
.previous1 {
  background-color: rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}
a.previous1 {
  margin-top: 22px;
  margin-left: 900px;
  padding: 8px 20px;
  height: 25%;
  font-size: 30px;
}
.previous1:hover {
  background-color: transparent;
  color: rgb(0, 0, 0);
}

.arrow {
  border: solid rgb(236, 66, 103);
  border-width: 0 9px 9px 0;
  display: inline-block;
  padding: 15px;
  background-color: transparent;
  opacity: 0.8;
  transition: 0.3s;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow:hover {
  opacity: 1;
  margin-left: 10px;
}
