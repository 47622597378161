.heading-section {
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--slate-800);
  gap: var(--gap-5xs);
  padding-top: -10px;
}
.tittle {
  letter-spacing: 0.02em;
  line-height: 17px;
  font-weight: 600;
}
.sub-title {
  font-size: var(--font-size-13xl);
  line-height: 130%;
  font-weight: 800;
}

.download-wrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 35px;
  margin-left: 20%;
}
.googleplay {
  width: 320px;
  object-fit: cover;
}
.appstore {
  width: 320px;
  object-fit: cover;
}

@media (max-width: 720px) {
  .download-wrapper {
    flex-direction: column;
    margin-top: 35px;
    margin-left: 0;
  }
  .sub-title {
    align-self: stretch;
    font-size: var(--font-size-5xl);
  }
  .googleplay {
    width: 220px;
  }
  .appstore {
    width: 220px;
  }
}
