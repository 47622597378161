/*    FLEX BOX       */
.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

/*    COLUMNS STYLE       */

.PadloveComponent {
  width: 100%;
  margin-top: 100px;
}

.columnStyleLeft {
  margin-bottom: 12px;
  margin-top: 12px;
  margin-right: 12px;
  border-radius: 8px;
  padding: 5px;
  height: auto;
  width: 250px;
}
.columnStyleRight {
  margin-bottom: 12px;
  margin-top: 12px;
  margin-left: 12px;
  border-radius: 8px;
  padding: 5px;
  height: auto;
  width: 250px;
}
.columnStyleMiddle {
  border-radius: 8px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 15px;
  height: auto;
}
.title {
  font-family: courgette;
  color: var(--primary-700);
  
  padding: 15px;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  overflow-wrap: break-word;
  margin: 0 auto;
}

.titleWrapper {
  width: calc(100% - 200px);
  display: none;
  justify-content:center ;
  align-items: center;
}

.top-nav-collapse .titleWrapper {
  display: flex;
}
/*    ROWS STYLE       */
.rowPadloveInfo {
 
  height: auto;
  /* margin-bottom: 15px; */
  margin-top: -10px;
  background-color: aliceblue;
  /* background-image: url(/web/public/img/valentineday.jpeg); */
}


.rowShareOn {
  height: 40px;
  padding: 2px;
}

.rowOurHistory {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  height: auto;
}
.rowMeetMarriedDate {
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* height: auto; */
  /* background-image: url("/img/valentineday.jpeg");*/
  padding: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}

.rowLovingStory {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  height: auto;
}

/*    OBJECT STYLE       */
.textContent {
  /* background-image: url("/img/valentineday.jpeg");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 40px;
  border-radius: 8px;
}
/* .textContent::after {
  position: absolute;
  opacity: 70%;
} */

header:after { content:" "; display:block; clear:both; }
.image-16-parent a{
   
  background-color: var(--slate-800);
  margin: 20px;
  border-radius: 5px;
}

.image-16-parent {
   height: 60px;
  align-items: center;
  justify-content: center;
   
}

.image-16-icon {
  position: relative;
  width: 160px;
  height: 40px;
  object-fit: cover;
  mix-blend-mode: lighten;
}

.padloveInfo {
  border-radius: 8px;
  padding: 5px;
  border-width: 8px;
  border-color: black;
}

.circle1 {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-top: 12px;
  margin-left: -106px;
  object-fit: cover;
}

.circle2 {
  /* width: 50px;
  height: 50px; */
  border-radius: 25px;
  object-fit: cover;
}
.circleModal {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}
.circle3 {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  object-fit: cover;
}

.yourName {
  font-size: 18px;
  flex:1;
  width: max-content;
  color: #e50051;
  font-family: courgette;
  font-weight: 600;
  text-align: end;
  margin-right: 51px;
  margin-top: -85px;
  padding: 5px;
}

.himHerName {
  font-size: 18px;
  flex: 1;
  color: #e50051;
  font-family: courgette;
  width: max-content;
  font-weight: 600;
  justify-content: flex-start;
  position: relative;
  left: 60px;
  
  margin-bottom: 50px;
  margin-top: -50px;
  padding: 5px;
}
.daysTogether {
  color: #e50051;
  font-family: courgette;
  font-weight: 600;
  margin-left: 63%;
}

.daysTogetherText {
  font-family: courgette;
  font-weight: 600;
  text-align: center;
  position: relative;
  margin-top: -30px;
  margin-bottom: 30px;
  left: 10px;
}

.likes {
  /* margin-left: 13px; */
  font-weight: 600;
  color: #1e293b;
  
}

.likesText {
  /* margin-left: 40%; */
  padding-left: 30px ;
  text-align: center;
  font-weight: 400px;

}


.followersText {
  /* margin-left: 10%; */
  padding-left: 30px ;
  text-align: center;
  font-weight: 400px;

}



.followsText {
  /* margin-left: 9%; */
  padding-left: 30px ;
  text-align: center;
  font-weight: 400px;
}

.meetMarriedDate {
  font-size: 13px;
  margin-left: 8px;
  margin-top: -2px;
  color: #e50051;
}
.likeCommentShareCount {
  flex:1;
  text-align: end;
  margin-right: 3px;
  font-size: 16px;
  font-weight: 400;
  color:#1e293b
}
.react-responsive-modal-modal {
  width: 100%;
  border-radius: 1%;
  background: rgb(0, 0, 0);
  margin-left: 0%;
  margin: 0rem;
}
.carousel .slider .slide {
  background: black;
}

@media screen and (max-width: 768px) {
  .titleWrapper {
    width: calc(100% - 50px);
    display: none;
    justify-content: center;
    align-items: center;
}
}

