/* @media (min-width: 768px)
.navbar {
padding: 0;
background: linear-gradient(150deg, #EC4267 15%, #EC4267 70%, #EC4267 94%);
}

.navbar {
padding: 0;
background: linear-gradient(150deg, #EC4267 15%, #EC4267 70%, #EC4267 94%);
} */

.TermsAndLegacies {
  margin: auto;
  width: 80%;
  margin-top: 100px;
}
.TermsAndLegacies p {
  color: #29303b;
  text-align: justify;
}
.title {
  float: left;
  height: 50px;
  padding: 15px;
  /* font-size: 18px; */
  line-height: 25px;
  display: flex;
  overflow-wrap: break-word;
}
h3,
h4,
h5 {
  margin: 10px 0 10px;
  padding: 0;
  color: #2c3e50;
  font-family: "Montserrat", sans-serif;
}
li {
  margin-bottom: 2px;
}
.navbar .navbar-brand {
  display: contents;
}

.navbar {
  height: 80px;
  font-family: var(--text-bold-l);
}

.navbar .nav.navbar-nav {
  padding-top: 0;
  padding-bottom: 0;
}

.navbar .navbar-collapse {
  border: none;
  align-items: center;
  width: 100%;
}

.navbar.top-nav-collapse {
  padding: 5px 0;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
}

.navbar .navbar-brand {
  margin-right: 40px;
  margin-left: 0 !important;
  padding-top: 0;
}
.navbar-collapse.collapse {
  background-color: transparent;
}


.navbar.top-nav-collapse .nav.navbar-nav  li a,
.navbar.top-nav-collapse .nav.navbar-nav.navbar-right li a  {
  color: var(--primary-700);
  justify-content: space-between;
  font-size: var(--text-default-size);
  /* font-family: var(--text-bold-l); */
 
}

.navbar .nav.navbar-nav li a,
.navbar .nav.navbar-nav.navbar-right li a {
  color: var(--color-white);
  justify-content: space-between;
  font-size: var(--text-default-size);
  /* font-family: var(--text-bold-l); */
  float: none !important;
  margin: 1px;
  text-transform: uppercase;
}

.navbar.top-nav-collapse  .nav.navbar-nav li.active a,
.navbar.top-nav-collapse  .nav.navbar-nav li a:hover {
  background: var(--primary-700);
  color: var(--color-white);  
}

.navbar .nav.navbar-nav li.active a,
.navbar .nav.navbar-nav li a:hover {
  background: var(--primary-700);
  color: var(--color-white);
  
}

/*mobile nav design*/
@media (max-width: 720px) {
  .navbar {
    background-color: transparent;
    border-bottom: transparent;
  }
  .navbar .navbar-brand.page-scroll {
    padding: 10px;
  }
  .navbar-collapse.collapse.in {
    background-color: #ffffff;
  }
  .navbar .nav.navbar-nav li a,
  .navbar .nav.navbar-nav.navbar-right li a {
    color: var(--efrei-dark);
  }
}
 {
  /*
  
  .navbar-header {
    background-color: transparent;
  }
  .navbar .navbar-toggle.collapsed {
    border: 2px solid #ffffff;
    background: transparent;
  }
  .navbar .navbar-toggle.collapsed .icon-bar {
    background-color: #ffffff;
  }

  .navbar .navbar-toggle.collapsed.scroll {
    border: 2px solid var(--primary-700);
    background: transparent;
  }
  .navbar .navbar-toggle.collapsed .icon-bar.scroll {
    background-color: var(--primary-700);
  }
  .navbar .navbar-brand.page-scroll {
    padding: 10px;
  }
}
*/
}
