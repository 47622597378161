.bridge-slider {
  display: block;
  width: 100%;
  overflow-x: scroll;
  padding-top: 0%;
}
.bridge-container {

  border-radius: 8px;
  display: block; 
}
.bridge-legend {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  width: 100%;
}
.bridge-nb-stories {
  font-weight: 900;
  color: black;
  padding-left: 10px;
  padding-top: 13px;
  padding-bottom: 1px;
}
.bridge-town {
  padding-left: 5px;
  padding-top: 13px;
  padding-bottom: 1px;
}
.slide-container {
  display: block;
  overflow-x: scroll;
  padding: 20px;
  box-sizing: border-box;
  -ms-overflow-style: none;
  overflow-anchor: auto;
}
.slide-container::-webkit-scrollbar {
  display: none;
}
.m-bridge-container {
  margin-bottom: 20px;
  display: inline-block;
  padding-left: 15px;
  width: 60%;
}
.m-bridge-legend {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding-top: 340px;
  width: 100%;
}
.m-bridge-nb-stories {
  font-weight: 600;
  font-size: 20px;
  color: whitesmoke;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.m-bridge-name {
  color: whitesmoke;
  font-size: 20px;
  padding-left: 5px;
  padding-top: 1px;
  padding-bottom: 1px;
  display: inline-flex;
}
.m-bridge-town {
  padding-left: 5px;
  font-size: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: white;
  display: flex;
}
@media screen and (max-width: 1200px) {
  .m-bridge-legend {
    width: auto;
    padding-top: 330px;
  }
  .m-bridge-nb-stories {
    font-weight: 600;
    font-size: 20px;
  }
  .m-bridge-name {
    font-size: 20px;
  }
  .m-bridge-town {
    font-size: 20px;
  }
}
@media screen and (max-width: 600px) {
  .m-bridge-container {
    margin-bottom: 20px;
    display: inline-block;
    padding-left: 15px;
    width: 95%;
  }
  .m-bridge-legend {
    width: auto;
    padding-top: 310px;
    align-items: center;
  }
  .m-bridge-nb-stories {
    font-weight: 600;
    font-size: 17px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .m-bridge-name {
    font-size: 17px;
    margin-left: 0px;
    padding-left: 0px;
  }
  .m-bridge-town {
    font-size: 14px;
    margin-left: 0px;
    padding-left: 0px;
  }
}
/* .btn-bridges {
  border-radius: 60%;
  background: transparent;
  color: rgb(236, 66, 103);
  font-size: 30px;
  font-weight: 600px;
  position: relative;
  top: 50%;
  right: 0px;
  bottom: 150px;
  margin-left: 10px;
  width: 80px;
  height: 80px;
  border-color: rgb(236, 66, 103);
  border-width: 2px;
} */
.btn-bridges:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(236, 66, 103);
  color: white;
}
.leftgrid {
  padding-top: 2px;
  margin-top: 5px;
}
.centergrid {
  padding-top: 2px;
  margin-top: 5px;
}
.rightgrid {
  margin-top: 5px;
  padding-top: 2px;
}

.bridgeheader {
  display: inline-flex;
  height: 60px;
  padding-left: 90px;
  padding-top: 10px;
  width: 100%;
}
.btn-bridges1 {
  border-radius: 15px;
  background: transparent;
  color: rgb(236, 66, 103);
  font-size: 20px;
  font-weight: 900px;
  width: 130px;
  margin-left: 700px;
  height: 40px;
  border-color: rgb(236, 66, 103);
  border-width: 2px;
}
.btn-bridges1:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: rgb(236, 66, 103);
  color: white;
}
.previous {
  background-color: rgb(236, 66, 103);
  color: white;
}
a.previous {
  margin-top: 22px;
  margin-left: 900px;
  padding: 8px 20px;
  height: 25%;
  font-size: 30px;
}
.previous:hover {
  background-color: #ddd;
  color: rgb(236, 66, 103);
}
.previous1 {
  background-color: rgb(236, 66, 103);
  color: white;
}
a.previous1 {
  margin-top: 22px;
  margin-left: 900px;
  padding: 8px 20px;
  height: 25%;
  font-size: 30px;
}
.previous1:hover {
  background-color: #ddd;
  color: rgb(236, 66, 103);
}

.horizontal-slider {
  display: block;
  width: 100%;
  overflow-x: scroll;
  position: relative;
}
.horizontal-slider::-webkit-scrollbar {
  display: none;
}
.slider-container {
  display: block;
  white-space: nowrap;
  position: relative;
}

.grid {
  display: inline-block;
  margin-top: 5%;
  position: relative;
  object-fit: cover;
  width: 100%;
}

.crossed {
  background: linear-gradient(
      to top left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(211, 211, 211, 211) 52%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      to top right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) calc(50% - 0.8px),
      rgba(211, 211, 211, 211) 52%,
      rgba(0, 0, 0, 0) calc(50% + 0.8px),
      rgba(0, 0, 0, 0) 100%
    );
}
