
  .grid-container {    
    
    width: 95%;
    margin: auto;
    -webkit-column-count: 5;
    -webkit-column-gap:   20px;
    -moz-column-count:    5;
    -moz-column-gap:      20px;
    column-count:         5;
    column-gap:           20px;
   
    padding: 0;

 }  
 
 .grid-item {
    width: 100% !important;
    height: auto !important;
    display: inline-block; /* for non-flex browsers */ 
  }

  .rowLovingStory {
    padding: 0px;
  }
  
  @media (max-width: 1200px) {
    .grid-container {
    -moz-column-count:    4;
    -webkit-column-count: 4;
    column-count:         4;
    }
  }
  @media (max-width: 1000px) {
    .grid-container {
    -moz-column-count:    3;
    -webkit-column-count: 3;
    column-count:         3;
    }
  }
  @media (max-width: 800px) {
    .grid-container {
    -moz-column-count:    2;
    -webkit-column-count: 2;
    column-count:         2;
    }
  }
  @media (max-width: 400px) {
    .grid-container {
    -moz-column-count:    1;
    -webkit-column-count: 1;
    column-count:         1;
    }
  }