.header-bg-img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  position: absolute;
}
.header-overlay {
  width: 100%;
  height: 600px;
  object-fit: cover;
  background: linear-gradient(90.14deg, #55212f 35.94%, rgba(85, 33, 47, 0.6));
  opacity: 0.6;
  mix-blend-mode: multiply;
}
.header-text-parent {
  /* position: absolute;
  top: 242px;
  left: 218px;*/
  width: 533px; 
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  font-size: 42px;
  color: var(--color-white);
}
.cadenas {
  align-self: stretch;
  position: relative;
  line-height: 117%;
}
.immortalisez {
  position: relative;
  font-size: var(--text-bold-2xl-size);
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
}
.padlove-wrapper {
  border-radius: 24px;
  background-color: var(--primary-700);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base);
  font-size: var(--text-s-size);
  cursor: pointer;
  color: var(--color-white);;
}
.padlove-wrapper:hover {
 
  color: rgba(255, 255, 255, 0.5);
}
a .mon-padlove {
  position: relative;
  letter-spacing: 0.06em;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
.phone-wrapper {
  /* position: absolute;
  left: 105%;
  width: 302.64px; */
  height: 465.71px;
  font-family: var(--text-bold-l);
}

.phone-img-1 {
  position: absolute;
  top: -60.19px;
  left: 118.89px;
  width: 167.95px;
  height: 367.31px;
  object-fit: cover;
}
.phone-1 {
  position: absolute;
  top: -68.19px;
  left: 107.55px;
  width: 191.44px;
  height: 385.31px;
  object-fit: cover;
}
.phone-img-2 {
  position: absolute;
  top: 21.12px;
  left: 4.56px;
  width: 173.41px;
  height: 368.67px;
  object-fit: cover;
}
.phone-2 {
  position: absolute;
  top: 12.4px;
  left: -5px;
  width: 191.44px;
  height: 385.31px;
}


.header-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 250px;
}

/*Mobile version*/
@media (max-width: 720px) {
  .header-bg-img {
    height: 576px;
    width: 100%;
  }

  .header-overlay {
    height: 576px;
    opacity: 0.8;
  }
  .header-text-parent {
    top: 101px;
    left: calc(50% - 175px);
    width: 350px;
    gap: var(--gap-13xl);
  }
  .header-body {
    
    padding-top: 120px;
  }
  .immortalisez {
    line-height: 117%;
    font-size: var(--heading-h3-size);
    line-height: 28px;
    align-self: stretch;
  }
  .padlove-wrapper {
    padding: var(--padding-base);
    cursor: pointer;
    font-size: var(--ui-s-size);
  }
  .phone-wrapper {
    position: relative;
    top: 30px;
    right: 0%;
    margin-left: 100px;
    height: 341.07px;
    font-family: var(--text-bold-l);
  }
  .phone-img-1 {
    top: 6px;
    left: 90px;
    width: 123px;
    height: 269px;
  }
  .phone-1 {
    top: 0;
    left: 81.44px;
    width: 140.2px;
    height: 282.19px;
  }
  .phone-img-2 {
    top: 66px;
    left: 7px;
    width: 127px;
    height: 270px;
  }
  .phone-2 {
    top: 58.88px;
    left: 0;
    width: 140.2px;
    height: 282.19px;
  }
}
