@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Neo+Sans+Pro:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --text-bold-l: Inter;
  --font-source-sans-pro: "Source Sans Pro";
  --font-neo-sans-pro: "Neo Sans Pro";

  /* font sizes */
  --font-size-smi: 13px;
  --text-default-size: 16px;
  --font-size-13xl: 32px;
  --font-size-xl: 20px;
  --font-size-xxl: 30px;
  --text-bold-l-size: 18px;
  --font-size-xs: 12px;
  --text-s-size: 14px;
  --text-ls-size: 12px;
  --text-bold-2xl-size: 24px;
  --font-size-5xl: 24px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #808080;
  --slate-800: #1e293b;
  --slate-700: #334155;
  --efrei-dark: #263047;
  --primary-700: #e50051;
  --slate-400: #94a3b8;
  --color-black: #000;
  --slate-50: #f8fafc;

  /* Gaps */
  --gap-8xs: 5px;
  --gap-base: 16px;
  --gap-sm: 14px;
  --gap-5xs: 8px;
  --gap-xs: 12px;
  --gap-11xs: 2px;
  --gap-10xs: 2px;
  --gap-61xl: 80px;
  --gap-5xl: 24px;
  --gap-21xl: 40px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-5xl: 24px;
  --padding-121xl: 140px;
  --padding-201xl: 220px;
  --padding-61xl: 80px;
  --padding-13xl: 32px;
  --padding-31xl: 50px;
  --padding-3xl: 22px;
  --padding-base: 16px;
  --padding-xs: 12px;
  --padding-xl: 20px;
  --padding-21xl: 40px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;
  --br-10xs: 3px;
  --br-base: 16px;

  /* Effects */
  --shadow-lg: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  /* text bold*/
  --text-bold-default-font-family: "Inter-Bold", Helvetica;
  --text-bold-default-font-size: 16px;
  --text-bold-default-font-style: normal;
  --text-bold-default-font-weight: 700;
  --text-bold-default-letter-spacing: 0px;
  --text-bold-default-line-height: 24px;

  --text-bold-default: Inter;
  --font-source-sans-pro: "Source Sans Pro";

  /* font sizes */
  --ui-s-size: 14px;
  --text-bold-l-size: 18px;
  --font-size-5xl: 24px;
  --heading-h3-size: 20px;
  --text-bold-default-size: 16px;
  --font-size-xs: 12px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #808080;
  --slate-800: #1e293b;
  --slate-700: #334155;
  --efrei-dark: #263047;
  --primary-700: #e50051;
  --color-black: #000;
  --slate-300: #cbd5e1;
  --slate-50: #f8fafc;
  --slate-400: #94a3b8;

  /* Gaps */
  --gap-5xs: 8px;
  --gap-3xs: 10px;
  --gap-5xl: 24px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-xs: 12px;
  --gap-21xl: 40px;
  --gap-9xs: 4px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-21xl: 40px;
  --padding-xs: 12px;
  --padding-5xl: 24px;
  --padding-5xs: 8px;
  --padding-3xs: 10px;
  --padding-61xl: 80px;
  --padding-base: 16px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-7xs: 6px;

  /* Effects */
  --shadow-lg: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  /*diverse*/
  --display-h1-font-family: "Inter-Regular", Helvetica;
  --display-h1-font-size: 80px;
  --display-h1-font-style: normal;
  --display-h1-font-weight: 400;
  --display-h1-letter-spacing: 0px;
  --display-h1-line-height: 80px;
  --display-h2-font-family: "Inter-Regular", Helvetica;
  --display-h2-font-size: 60px;
  --display-h2-font-style: normal;
  --display-h2-font-weight: 400;
  --display-h2-letter-spacing: 0px;
  --display-h2-line-height: 60px;
  --display-h3-font-family: "Inter-Regular", Helvetica;
  --display-h3-font-size: 48px;
  --display-h3-font-style: normal;
  --display-h3-font-weight: 400;
  --display-h3-letter-spacing: 0px;
  --display-h3-line-height: 48px;
  --display-h4-font-family: "Inter-Regular", Helvetica;
  --display-h4-font-size: 36px;
  --display-h4-font-style: normal;
  --display-h4-font-weight: 400;
  --display-h4-letter-spacing: 0px;
  --display-h4-line-height: 40px;
  --display-h5-font-family: "Inter-Regular", Helvetica;
  --display-h5-font-size: 24px;
  --display-h5-font-style: normal;
  --display-h5-font-weight: 400;
  --display-h5-letter-spacing: 0px;
  --display-h5-line-height: 32px;
  --display-h6-font-family: "Inter-Regular", Helvetica;
  --display-h6-font-size: 20px;
  --display-h6-font-style: normal;
  --display-h6-font-weight: 400;
  --display-h6-letter-spacing: 0px;
  --display-h6-line-height: 28px;
  --heading-h1-font-family: "Inter-Regular", Helvetica;
  --heading-h1-font-size: 30px;
  --heading-h1-font-style: normal;
  --heading-h1-font-weight: 400;
  --heading-h1-letter-spacing: 0px;
  --heading-h1-line-height: 36px;
  --heading-h2-font-family: "Inter-Regular", Helvetica;
  --heading-h2-font-size: 24px;
  --heading-h2-font-style: normal;
  --heading-h2-font-weight: 400;
  --heading-h2-letter-spacing: 0px;
  --heading-h2-line-height: 32px;
  --heading-h3-font-family: "Inter-Regular", Helvetica;
  --heading-h3-font-size: 20px;
  --heading-h3-font-style: normal;
  --heading-h3-font-weight: 400;
  --heading-h3-letter-spacing: 0px;
  --heading-h3-line-height: 28px;
  --heading-h4-font-family: "Inter-Regular", Helvetica;
  --heading-h4-font-size: 18px;
  --heading-h4-font-style: normal;
  --heading-h4-font-weight: 400;
  --heading-h4-letter-spacing: 0px;
  --heading-h4-line-height: 28px;
  --heading-h5-font-family: "Inter-Regular", Helvetica;
  --heading-h5-font-size: 16px;
  --heading-h5-font-style: normal;
  --heading-h5-font-weight: 400;
  --heading-h5-letter-spacing: 0px;
  --heading-h5-line-height: 24px;
  --heading-h6-font-family: "Inter-Regular", Helvetica;
  --heading-h6-font-size: 14px;
  --heading-h6-font-style: normal;
  --heading-h6-font-weight: 400;
  --heading-h6-letter-spacing: 0px;
  --heading-h6-line-height: 20px;
  --primary-100: rgba(255, 225, 231, 1);
  --primary-200: rgba(255, 200, 213, 1);
  --primary-300: rgba(255, 155, 179, 1);
  --primary-400: rgba(255, 99, 140, 1);
  --primary-50: rgba(255, 240, 243, 1);
  --primary-500: rgba(255, 44, 105, 1);
  --primary-600: rgba(246, 8, 86, 1);
  --primary-700: rgba(229, 0, 81, 1);
  --primary-800: rgba(174, 3, 69, 1);
  --primary-900: rgba(148, 7, 65, 1);
  --primary-950: rgba(83, 0, 31, 1);
  --shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.1),
    0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  --shadow-2xl: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-inner: ;
  --shadow-lg: 0px 4px 6px -4px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 2px 4px -2px rgba(0, 0, 0, 0.1),
    0px 4px 6px -1px rgba(0, 0, 0, 0.25);
  --shadow-sm: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0px 8px 10px -6px rgba(0, 0, 0, 0.1),
    0px 20px 25px -5px rgba(0, 0, 0, 0.1);
  --slate-100: rgba(241, 245, 249, 1);
  --slate-200: rgba(226, 232, 240, 1);
  --slate-300: rgba(203, 213, 225, 1);
  --slate-400: rgba(148, 163, 184, 1);
  --slate-50: rgba(248, 250, 252, 1);
  --slate-500: rgba(100, 116, 139, 1);
  --slate-600: rgba(71, 85, 105, 1);
  --slate-700: rgba(51, 65, 85, 1);
  --slate-800: rgba(30, 41, 59, 1);
  --slate-900: rgba(15, 23, 42, 1);
  --slate-950: rgba(2, 6, 23, 1);
  --text-2xl-font-family: "Inter-Regular", Helvetica;
  --text-2xl-font-size: 24px;
  --text-2xl-font-style: normal;
  --text-2xl-font-weight: 400;
  --text-2xl-letter-spacing: 0px;
  --text-2xl-line-height: 32px;
  --text-bold-2xl-font-family: "Inter-Bold", Helvetica;
  --text-bold-2xl-font-size: 24px;
  --text-bold-2xl-font-style: normal;
  --text-bold-2xl-font-weight: 700;
  --text-bold-2xl-letter-spacing: 0px;
  --text-bold-2xl-line-height: 32px;
  --text-bold-default-font-family: "Inter-Bold", Helvetica;
  --text-bold-default-font-size: 16px;
  --text-bold-default-font-style: normal;
  --text-bold-default-font-weight: 700;
  --text-bold-default-letter-spacing: 0px;
  --text-bold-default-line-height: 24px;
  --text-bold-l-font-family: "Inter-Bold", Helvetica;
  --text-bold-l-font-size: 18px;
  --text-bold-l-font-style: normal;
  --text-bold-l-font-weight: 700;
  --text-bold-l-letter-spacing: 0px;
  --text-bold-l-line-height: 28px;
  --text-bold-s-font-family: "Inter-Bold", Helvetica;
  --text-bold-s-font-size: 14px;
  --text-bold-s-font-style: normal;
  --text-bold-s-font-weight: 700;
  --text-bold-s-letter-spacing: 0px;
  --text-bold-s-line-height: 29px;
  --text-bold-XL-font-family: "Inter-Bold", Helvetica;
  --text-bold-XL-font-size: 20px;
  --text-bold-XL-font-style: normal;
  --text-bold-XL-font-weight: 700;
  --text-bold-XL-letter-spacing: 0px;
  --text-bold-XL-line-height: 28px;
  --text-bold-XS-font-family: "Inter-Bold", Helvetica;
  --text-bold-XS-font-size: 12px;
  --text-bold-XS-font-style: normal;
  --text-bold-XS-font-weight: 700;
  --text-bold-XS-letter-spacing: 0px;
  --text-bold-XS-line-height: 16px;
  --text-default-font-family: "Inter-Regular", Helvetica;
  --text-default-font-size: 16px;
  --text-default-font-style: normal;
  --text-default-font-weight: 400;
  --text-default-letter-spacing: 0px;
  --text-default-line-height: 24px;
  --text-l-font-family: "Inter-Regular", Helvetica;
  --text-l-font-size: 18px;
  --text-l-font-style: normal;
  --text-l-font-weight: 400;
  --text-l-letter-spacing: 0px;
  --text-l-line-height: 28px;
  --text-s-font-family: "Inter-Regular", Helvetica;
  --text-s-font-size: 14px;
  --text-s-font-style: normal;
  --text-s-font-weight: 400;
  --text-s-letter-spacing: 0px;
  --text-s-line-height: 20px;
  --text-XL-font-family: "Inter-Regular", Helvetica;
  --text-XL-font-size: 20px;
  --text-XL-font-style: normal;
  --text-XL-font-weight: 400;
  --text-XL-letter-spacing: 0px;
  --text-XL-line-height: 28px;
  --text-XS-font-family: "Inter-Regular", Helvetica;
  --text-XS-font-size: 12px;
  --text-XS-font-style: normal;
  --text-XS-font-weight: 400;
  --text-XS-letter-spacing: 0px;
  --text-XS-line-height: 16px;
  --UI-2xl-font-family: "Inter-SemiBold", Helvetica;
  --UI-2xl-font-size: 24px;
  --UI-2xl-font-style: normal;
  --UI-2xl-font-weight: 600;
  --UI-2xl-letter-spacing: 1.44px;
  --UI-2xl-line-height: 32px;
  --UI-default-font-family: "Inter-SemiBold", Helvetica;
  --UI-default-font-size: 16px;
  --UI-default-font-style: normal;
  --UI-default-font-weight: 600;
  --UI-default-letter-spacing: 0.96px;
  --UI-default-line-height: 24px;
  --UI-l-font-family: "Inter-SemiBold", Helvetica;
  --UI-l-font-size: 18px;
  --UI-l-font-style: normal;
  --UI-l-font-weight: 600;
  --UI-l-letter-spacing: 1.08px;
  --UI-l-line-height: 28px;
  --UI-s-font-family: "Inter-SemiBold", Helvetica;
  --UI-s-font-size: 14px;
  --UI-s-font-style: normal;
  --UI-s-font-weight: 600;
  --UI-s-letter-spacing: 0.84px;
  --UI-s-line-height: 20px;
  --UI-XL-font-family: "Inter-SemiBold", Helvetica;
  --UI-XL-font-size: 20px;
  --UI-XL-font-style: normal;
  --UI-XL-font-weight: 600;
  --UI-XL-letter-spacing: 1.2px;
  --UI-XL-line-height: 28px;
  --UI-XS-font-family: "Inter-SemiBold", Helvetica;
  --UI-XS-font-size: 12px;
  --UI-XS-font-style: normal;
  --UI-XS-font-weight: 600;
  --UI-XS-letter-spacing: 0.72px;
  --UI-XS-line-height: 16px;
}
