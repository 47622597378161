.heading-section {
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--slate-800);
  gap: var(--gap-5xs);
  margin-top: 50px;
}
.tittle {
  letter-spacing: 0.02em;
  line-height: 17px;
  font-weight: 600;
}
.sub-title {
  font-size: var(--font-size-13xl);
  line-height: 130%;
  font-weight: 800;
}
.menu-section {
  margin: 20px;
  margin-bottom: 50px;
  display: flex;
  overflow-x: auto;
  flex-shrink: 0;
  align-items: flex-start;
  gap: 30px;
  align-items: center;
  justify-content: center;
  text-wrap: nowrap;
  padding-left: 0px;
  color: var(--slate-800);
  font-size: var(--font-size-xl);
}
.menu-section::-webkit-scrollbar {
  width: 0;
}
.menu-1 {
  letter-spacing: 0.02em;
  line-height: 17px;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  text-transform: uppercase; 
}
.menu-1.active {
  background-color: var(--primary-700);
  color: var(--color-white);
}
.bridges-container {
  margin: auto;
  margin-top: 50px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  /* justify-content: center; */
}
.bridges-container::-webkit-scrollbar {
  display: none;
}
.bridge-1 {
  min-width: 330px;
  background-size: cover;
  background-repeat: no-repeat;
  height: 324px;
  margin-right: 20px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 10px 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.overlay {
  min-width: 330px;
  height: 324px;
  background: linear-gradient(-0.69deg, #55212f, rgba(85, 33, 47, 0));
  opacity: 0.6;
  position: absolute;
  border-radius: 20px;
  object-fit: cover;
}
.bridge-name {
  text-align: left;
  font-size: var(--text-bold-2xl-size);
  color: var(--color-white);
  font-family: var(--text-bold-l);
  font-weight: bold;
}
.padloves-num {
  text-align: left;
  color: var(--color-white);
  font-family: var(--text-bold-l);
  font-size: var(--text-bold-l-size);
  line-height: 28px;
}

.bridge-name {
  color: var(--color-white);
  font-family: courgette;
  font-size: 22px;
  padding-left: 5px;
  margin-bottom: 6%;
}

@media (max-width: 720px) {
  .bridge-1 {
    min-width: 230px;
    height: 224px;
    margin-right: 10px;
  }
  .overlay {
    min-width: 230px;
    height: 224px;
  }
  .bridges-container {
    margin-left: 0;
  }
  .bridge-name {
    font-size: var(--text-bold-l-size);
    font-family: var(--text-bold-default);
  }
  .padloves-num {
    font-family: var(--text-bold-default);
  }
  .menu-section {
    margin-top: 20px;
    display: flex;
    overflow-x: auto;
    flex-shrink: 0;
    align-items: flex-start;
    gap: 10px;
    align-items: center;
    justify-content: left;
    text-wrap: nowrap;
    padding-left: 0px;
    color: var(--slate-800);
    font-size: var(--font-size-xl);
  }
}
