.gridcontainer {
  border-style: solid;
  border-width: 4px;
  border-image: radial-gradient(lightgray, #363738, white) 1;
  background: transparent;
  position: absolute;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
}
.box {
  width: inherit;
  height: inherit;
  background-image: repeating-linear-gradient(
    -45deg,
    white 2px,
    lightgray,
    #363738,
    transparent 5px,
    transparent 45px
  );
  position: absolute;
  z-index: -1;
}
.box1 {
  width: inherit;
  height: inherit;
  background-image: repeating-linear-gradient(
    45deg,
    white 2px,
    lightgray,
    #363738,
    transparent 5px,
    transparent 45px
  );
  z-index: -1;
  position: absolute;
}
