/*====================================
			 1. Map css
====================================*/
.map-container {
  height: 700px;
  position: absolute;

  left: 0;
  right: 0;
}

#padloveLogo {
  height: 70px;
  width: 70px;
  background-image: url("../public/padlove_logo_white.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.navbar.top-nav-collapse #padloveLogo {
  background-image: url("../public/padlove_logo_ping.png");
}

.testimonials {
  /* position: absolute;
top: 3725px; */
  /* left: calc(50% - 720px); */
  background: linear-gradient(267.97deg, #ae0345, #ff638c);
  width: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
}
.heading-section-rp {
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  gap: var(--gap-5xs);
  padding-top: -10px;
}
.tittle {
  letter-spacing: 0.02em;
  line-height: 17px;
  font-weight: 600;
}
.sub-title {
  font-size: var(--font-size-13xl);
  line-height: 130%;
  font-weight: 800;
}

.romantic-sites {
  
  height: 700px;
  display: block;
}