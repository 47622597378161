.heading-section {
  text-align: center;
  font-size: var(--font-size-xl);
  color: var(--slate-800);
  gap: var(--gap-5xs);
  padding-top: 50px;
}

.tittle {
  letter-spacing: 0.02em;
  line-height: 17px;
  font-weight: 600;
}
.sub-title {
  font-size: var(--font-size-13xl);
  line-height: 130%;
  font-weight: 800;
}
.ellipse-parent {
  /*position: relative;*/
  background-color: transparent;
  width: 48px;
  height: 48px;
}
.ellipse-div {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--primary-700);
  box-sizing: border-box;
}
.ellipse-div.active {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--primary-700);
  border: 1px solid var(--primary-700);
  box-sizing: border-box;
}
.item-numb {
  position: relative;
  line-height: 28px;
  display: inline-block;
  width: 28px;
  top: 10px;
  left: 20px;
  color: var(--efrei-dark);
}
.item-numb.active {
  position: relative;
  line-height: 28px;
  display: inline-block;
  width: 28px;
  top: 10px;
  left: 20px;
  color: var(--color-white);
}
.create-padlove-wrapper {
  padding: var(--padding-xs) 0 0;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--text-default-size);
  color: var(--color-black);
  left: 30px;
}

.item {
  line-height: 130%;
}
.description {
  font-size: var(--text-s-size);
  line-height: 20px;
  color: var(--color-gray-100);
  height: 11px;
}
.line-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-5xl);
}
.line {
  align-self: stretch;
  border-right: 1px solid var(--primary-700);
  box-sizing: border-box;
  width: 30px;
  height: 50px;
  position: relative;
}
.about-img {
  width: 460px;
  height: 330px;
  object-fit: cover;
}

/*Mobile version*/
@media (max-width: 720px) {
  .heading-section {
    padding-top: 10px;
  }
  .about-description {
    padding-top: 0px;
  }
  .sub-title {
    font-size: var(--font-size-5xl);
  }
  .about-img {
    width: 350px;
    height: 200px;
  }
  .description {
    align-self: stretch;
    padding-top: 5px;
  }
  .item {
    line-height: 130%;
    font-size: var(--text-s-size);
  }
}
